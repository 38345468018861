<script lang="ts" setup>
import { useTextareaAutosize } from '@vueuse/core';

interface Props {
  modelValue: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{ 'update:modelValue': [value: string] }>();

const { textarea, input } = useTextareaAutosize({ styleProp: 'minHeight' });

watch(
  () => props.modelValue,
  () => {
    input.value = props.modelValue?.toString() || '';
  },
);

const focus = () => {
  textarea.value?.focus();
};

defineExpose({
  focus,
});
</script>

<template>
  <!-- @vue-expect-error Property 'value' does not exist on type 'EventTarget' -->
  <textarea class="text-lg" ref="textarea" :value="props.modelValue" v-bind="$attrs" @input="emit('update:modelValue', $event.target?.value)" />
</template>
