<script lang="ts" setup>
import type { InputAttributes } from '~ui/types/inputAttributes';
import type { TextInputTypes } from '~ui/types/textInputTypes';
import UITextareaAutoGrow from '../UITextareaAutoGrow.vue';
import UIWrapperInput from '../Wrapper/UIWrapperInput.vue';

interface Props extends InputAttributes {
  variant?: 'default' | 'underline' | 'emphasised';
  type?: TextInputTypes;
  placeholder?: string | undefined;
  errorMessages?: string[];
  isRounded?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  autocomplete?: string;
  customClasses?: string;
  dataTestid?: string;
  buttonType?: 'arrow-right';
  autoGrow?: boolean;
  isActive?: boolean;
  maxLength?: number;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'default',
  type: 'text',
  isRounded: false,
  errorMessages: () => [],
  placeholder: undefined,
  autocomplete: undefined,
  hideErrorMessage: false,
  customClasses: undefined,
  dataTestid: undefined,
  buttonType: undefined,
  autoGrow: false,
  isActive: true,
  maxLength: 300,
});

const value = defineModel<string | number>({ required: false });

const emit = defineEmits<{
  blur: [event: FocusEvent];
  handleButtonClick: [];
}>();

// Handle character limit
const enforceCharacterLimit = () => {
  if (typeof value.value === 'string' && value.value.length > props.maxLength) {
    value.value = value.value.substring(0, props.maxLength);
  }
};

const hasError = computed<boolean>(() => !!props.errorMessages.length);
const hasValue = computed(() => {
  if (!value.value) {
    return false;
  }
  return value.value !== '';
});

const inputState = computed(() => {
  if (props.disabled) {
    return INPUT_STATE_DISABLED;
  }

  if (hasError.value) {
    return INPUT_STATE_ERROR;
  }

  if (hasValue.value) {
    return INPUT_STATE_VALID;
  }

  return INPUT_STATE_DEFAULT;
});

const hasButton = computed(() => !!props.buttonType);

const variantClasses = computed<string>(() => {
  return twMerge(
    cva('p-4 text-lg font-normal', {
      variants: {
        isRounded: {
          true: props.autoGrow ? 'rounded-3xl' : 'rounded-4xl',
          false: 'rounded-lg',
        },
        isActive: {
          true: 'bg-white',
          false: 'bg-transparent',
        },
        inputState: {
          [INPUT_STATE_ERROR]: 'border-red-400',
          [INPUT_STATE_VALID]: 'border-green-400',
          [INPUT_STATE_DEFAULT]: 'border-primary-400',
          [INPUT_STATE_DISABLED]: 'border-grey-400 text-grey-500',
        },
        variant: {
          underline: 'w-full max-w-96 !rounded-none border-b-2 !border-b-grey-400 bg-transparent text-center text-white',
          default: 'h-[50px] w-full border placeholder-grey-600',
          emphasised: 'w-full placeholder-grey-500 drop-shadow-[0_6px_8px_rgba(2,0,98,0.15)]',
        },
        hasButton: {
          true: 'pr-14',
        },
        autoGrow: {
          true: 'block resize-none',
        },
      },
    })({
      isRounded: props.isRounded,
      inputState: inputState.value,
      variant: props.variant,
      hasButton: hasButton.value,
      autoGrow: props.autoGrow,
      isActive: props.isActive,
    }),
    props.customClasses,
  );
});

const buttonClasses = computed(() => {
  return twMerge(
    cva('absolute right-2 top-1/2 flex size-10 -translate-y-1/2 transform items-center justify-center rounded-full bg-secondary-500 text-white', {
      variants: {
        disabled: {
          true: 'bg-grey-300',
          false: '',
        },
      },
    })({
      disabled: props.disabled,
    }),
  );
});

const inputRef = ref<HTMLInputElement | HTMLTextAreaElement | null>(null);

const focus = () => {
  inputRef.value?.focus();
};

watch(
  () => value.value,
  () => {
    enforceCharacterLimit();
  },
);

defineExpose({
  focus,
});

const elementId = useId();

const inputType = computed(() => (props.autoGrow ? UITextareaAutoGrow : UIWrapperInput));
</script>

<template>
  <div>
    <UIInputLabel v-if="!hideLabel" :for="elementId" :label="label" :required="required" />
    <div class="relative">
      <component
        :is="inputType"
        :id="elementId"
        ref="inputRef"
        v-model="value"
        :aria-required="required"
        :autocomplete="autocomplete"
        :class="variantClasses"
        :data-testid="dataTestid"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :type="type"
        rows="1"
        @blur="emit('blur', $event)"
      />
      <button v-if="buttonType" :aria-label="label" :disabled="disabled" :class="buttonClasses" @click="emit('handleButtonClick')">
        <UIAssetIcon :path="buttonType" />
      </button>
    </div>
    <UIInputErrors
      v-if="!hideErrorMessage && errorMessages.length"
      :data-testid="`error-message-${elementId}`"
      :errorMessages="errorMessages"
      :fontSize="errorMessageSize"
    />
  </div>
</template>
