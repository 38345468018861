<script lang="ts" setup>
interface Props {
  modelValue: string | number | undefined;
}

const props = defineProps<Props>();
const emit = defineEmits<{ 'update:modelValue': [value: string] }>();

const inputRef = ref<HTMLInputElement | null>(null);

const focus = () => {
  inputRef.value?.focus();
};

defineExpose({
  focus,
});
</script>

<template>
  <!-- @vue-expect-error undefined value -->
  <input ref="inputRef" :value="props.modelValue" v-bind="$attrs" @input="emit('update:modelValue', $event.target?.value)" />
</template>
